//
// Buttons Theme
//

// Outline dashed basic style
.btn.btn-outline.btn-outline-dashed {
  border-width: 1px;
  border-style: dashed;

  $color: null;
  $icon-color: null;
  $border-color: null;
  $bg-color: null;

  $color-active: null;
  $icon-color-active: null;
  $border-color-active: var(--#{$prefix}primary);
  $bg-color-active: null;

  @include button-custom-variant(
    $color,
    $icon-color,
    $border-color,
    $bg-color,
    $color-active,
    $icon-color-active,
    $border-color-active,
    $bg-color-active
  );
}

// Theme colors
@each $name, $value in $theme-colors {
  // Base
  .btn.btn-#{$name} {
    $color: var(--#{$prefix}#{$name}-inverse);
    $icon-color: var(--#{$prefix}#{$name}-inverse);
    $border-color: var(--#{$prefix}#{$name});
    $bg-color: var(--#{$prefix}#{$name});

    $color-active: var(--#{$prefix}#{$name}-inverse);
    $icon-color-active: var(--#{$prefix}#{$name}-inverse);
    $border-color-active: var(--#{$prefix}#{$name}-active);
    $bg-color-active: var(--#{$prefix}#{$name}-active);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Light
  @if (theme-light-color($name)) {
    .btn.btn-light-#{$name} {
      $color: var(--#{$prefix}#{$name});
      $icon-color: var(--#{$prefix}#{$name});
      $border-color: var(--#{$prefix}#{$name}-light);
      $bg-color: var(--#{$prefix}#{$name}-light);

      $color-active: var(--#{$prefix}#{$name}-inverse);
      $icon-color-active: var(--#{$prefix}#{$name}-inverse);
      $border-color-active: var(--#{$prefix}#{$name});
      $bg-color-active: var(--#{$prefix}#{$name});

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }

  // Background
  .btn.btn-bg-#{$name} {
    $color: null;
    $icon-color: null;
    $border-color: var(--#{$prefix}#{$name});
    $bg-color: var(--#{$prefix}#{$name});

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Active
  .btn.btn-active-#{$name} {
    $color: null;
    $icon-color: null;
    $border-color: null;
    $bg-color: null;

    $color-active: var(--#{$prefix}#{$name}-inverse);
    $icon-color-active: var(--#{$prefix}#{$name}-inverse);
    $border-color-active: var(--#{$prefix}#{$name});
    $bg-color-active: var(--#{$prefix}#{$name});

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Light
  @if (theme-light-color($name)) {
    .btn.btn-light-#{$name} {
      $color: null;
      $icon-color: null;
      $border-color: null;
      $bg-color: null;

      $color-active: var(--#{$prefix}#{$name});
      $icon-color-active: var(--#{$prefix}#{$name});
      $border-color-active: var(--#{$prefix}#{$name}-light);
      $bg-color-active: var(--#{$prefix}#{$name}-light);

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }

  // Outline
  .btn.btn-outline.btn-outline-#{$name} {
    $color: var(--#{$prefix}#{$name});
    $icon-color: var(--#{$prefix}#{$name});
    $border-color: var(--#{$prefix}#{$name});
    $bg-color: transparent;

    $color-active: var(--#{$prefix}#{$name}-active);
    $icon-color-active: var(--#{$prefix}#{$name}-active);
    $border-color-active: var(--#{$prefix}#{$name});
    $bg-color-active: var(--#{$prefix}#{$name}-light);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
}

// Theme text colors
@each $name, $value in $theme-text-colors {
  // Text and icon colors
  .btn.btn-color-#{$name} {
    $color: var(--#{$prefix}text-#{$name});
    $icon-color: var(--#{$prefix}text-#{$name});
    $bg-color: null;
    $border-color: null;

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Text and icon colors active state
  .btn.btn-active-color-#{$name} {
    $color: null;
    $icon-color: null;
    $border-color: null;
    $bg-color: null;

    $color-active: var(--#{$prefix}text-#{$name});
    $icon-color-active: var(--#{$prefix}text-#{$name});
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Icon colors
  .btn.btn-icon-#{$name} {
    $color: null;
    $icon-color: var(--#{$prefix}text-#{$name});
    $bg-color: null;
    $border-color: null;

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Icon colors active state
  .btn.btn-active-icon-#{$name} {
    $color: null;
    $icon-color: null;
    $bg-color: null;
    $border-color: null;

    $color-active: null;
    $icon-color-active: var(--#{$prefix}text-#{$name});
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Text colors
  .btn.btn-text-#{$name} {
    $color: var(--#{$prefix}text-#{$name});
    $icon-color: null;
    $bg-color: null;
    $border-color: null;

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Text colors active state
  .btn.btn-active-text-#{$name} {
    $color: null;
    $icon-color: null;
    $bg-color: null;
    $border-color: null;

    $color-active: var(--#{$prefix}text-#{$name});
    $icon-color-active: null;
    $border-color-active: null;
    $bg-color-active: null;

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
}

// Social colors
@each $name, $value in $social-colors {
  // Base
  .btn.btn-#{$name} {
    $color: get($value, inverse);
    $icon-color: get($value, inverse);
    $border-color: get($value, base);
    $bg-color: get($value, base);

    $color-active: null;
    $icon-color-active: null;
    $border-color-active: get($value, active);
    $bg-color-active: get($value, active);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }

  // Light
  .btn.btn-light-#{$name} {
    $color: get($value, base);
    $icon-color: get($value, base);
    $bg-color: get($value, light);
    $border-color: get($value, light);

    $color-active: get($value, inverse);
    $icon-color-active: get($value, inverse);
    $bg-color-active: get($value, base);
    $border-color-active: get($value, base);

    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
}
