/* Hide the arrows/spin buttons in number inputs */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

div.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: #aaaaaa;
}

div.disabled input,
div.disabled button,
div.disabled textarea,
div.disabled select {
  cursor: not-allowed;
}

.list-bank-item {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.list-bank-item:hover {
  background-color: #f9f9f9;
}

.btn-xs {
  padding: 3px 12px !important;
  font-size: 11px !important;
}

.text-right {
  text-align: right;
}